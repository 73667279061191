.user-update-post {
    margin-bottom: 15px;
    padding: 20px;
    .title {
        margin: 0 !important;
        cursor: pointer;
        //text-align: center;
    }
    .created {
        margin: 0 !important;
        padding-top: 10px;
        font-size: 12px !important;
        color: var(--darkgrey);
    }
    .post-body {
        padding-top: 10px;
    }
}
.user-update-post.collapsed {
    overflow: hidden;
    //text-align: center;
    cursor: pointer;
    img {
        width: 100%;
        object-fit: cover;
    }
}

.user-update-post.loading,
.user-update-post.error,
.user-update-post.default,
.user-update-post.nomore {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
        text-align: center;
        margin: 0 !important;
    }
    a {
        font-weight: bold;
    }
}
