.user-header {
    background-color: var(--white);
    padding: 20px;
    display: flex;
    align-items: center;
    .user-image {
        max-width: 115px;
        min-width: 115px;
        min-height: 115px;
        max-height: 115px;
        display: block;
        border-radius: 100px;
        background-color: var(--lightestgrey);
        img {
            border-radius: 100px;
            width: 100%;
            height: 100%;
            object-fit: cover;
            cursor: pointer;
        }
    }
    .user-about {
        padding-left: 20px;
        display: flex;
        flex-direction: column;
        min-width: 0;
        p {
            padding: 5px 0px;
        }
        .user-about-text {
            font-size: 15px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            a {
                text-transform: capitalize;
                cursor: pointer;
                font-weight: bold;
            }
            .error-link {
                text-transform: none;
            }
        }
        .user-about-text.expanded {
            display: block;
        }
        .user-supportercount {
            color: var(--darkgrey);
            font-size: 14px;
            display: inline;
        }
        .user-supportercount:hover {
            cursor: pointer;
            text-decoration: underline;
        }
    }
}
