.main-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    padding: 10px 20px;
    //box-shadow: var(--softshadow-bottom);
    position: sticky;
    z-index: 10;
    top: 0;
    height: 62px;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.9);
    img {
        width: 42px;
    }
    h1 {
        letter-spacing: 0.5px;
        padding-top: 1px;
        box-sizing: border-box;
        //padding-left: 20px;
        font-size: 25px;
        font-weight: 600;
        letter-spacing: 1.6px;
        margin: 0;
        font-family: "Kandy Yum";
    }
}
