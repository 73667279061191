.home-info {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    transition: flex-grow 0.25s linear;
    .home-info-inner {
        margin: 0 auto;
        max-width: 750px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px;
        .home-info-text {
            line-height: 1.6;
            font-size: 18px;
            letter-spacing: 0.5px;
            padding-bottom: 15px;
        }
        .home-info-text-two {
            line-height: 1.6;
            font-size: 14px;
            letter-spacing: 0.5px;
            padding-bottom: 15px;
            max-width: 750px;
        }
        .home-cta-input {
            max-width: 750px;
            display: flex;
            font-size: 16px;
            letter-spacing: 0.5px;
            p {
                padding: 15px 0px 15px 10px;
                border-left: 2px solid var(--lightgrey);
                border-bottom: 2px solid var(--lightgrey);
                border-top: 2px solid var(--lightgrey);
                border-top-left-radius: var(--br-one);
                border-bottom-left-radius: var(--br-one);
            }
            input {
                flex-grow: 1;
                padding: 15px 10px 15px 10px;
                border-right: 2px solid var(--lightgrey);
                border-bottom: 2px solid var(--lightgrey);
                border-top: 2px solid var(--lightgrey);
                border-left: 2px solid var(--lightgrey);
                min-width: 0;
                border-top-right-radius: var(--br-one);
                border-bottom-right-radius: var(--br-one);
                border-top-left-radius: var(--br-one);
                border-bottom-left-radius: var(--br-one);
            }
            input::placeholder {
                color: var(--darkgrey);
            }
            input:focus {
                box-shadow: 0 0 10px rgb(119, 119, 119);
                border: 2px solid var(--white);
            }
        }
        .home-cta-buttons {
            max-width: 750px;
            padding-top: 15px;
            padding-bottom: 15px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 15px;
        }
    }
}
