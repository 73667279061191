.user-tabs {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    background-color: rgba(255, 255, 255, 0.9);
    //padding: 15px 0px;
    position: sticky;
    z-index: 10;
    top: 0;
    p {
        text-transform: capitalize;
        cursor: pointer;
        padding: 15px 10px;
        width: 100%;
        height: 100%;
        text-align: center;
    }
    .active {
        font-weight: bold;
    }
}
.hidden {
    display: none;
}
.tabview-container {
    padding: 15px 0px;
    .tab-view {
        min-height: calc(100vh - 80px);
        .user-home-post,
        .user-update-post {
            background-color: var(--white);
            overflow-wrap: break-word;
            * {
                overflow-wrap: break-word;
            }
            table {
                table-layout: fixed;
                width: 100%;
            }
            ul {
                list-style-position: inside;
            }
            background-color: var(--white);
            img {
                display: block;
                max-width: 100%;
            }
            h1 {
                margin-top: 10px;
                margin-bottom: 10px;
            }

            h2 {
                margin-top: 10px;
                margin-bottom: 10px;
            }

            h3 {
                margin-top: 10px;
                margin-bottom: 10px;
            }

            h4 {
                margin-top: 10px;
                margin-bottom: 10px;
            }

            h5 {
                margin-top: 10px;
                margin-bottom: 10px;
            }

            h6 {
                margin-top: 10px;
                margin-bottom: 10px;
            }
            p {
                //font-size: 14px;
                //line-height: 1.4;
                margin-bottom: 10px;
            }
            a {
                color: rgb(32, 147, 242);
            }
            //AFTER HERE COPIED CSS FROM PEAKD
            [class^="icon-"],
            [class*=" icon-"] {
                font-family: "icomoon";
                speak: none;
                font-style: normal;
                font-weight: normal;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                min-width: 1em;
                display: inline-block;
                text-align: center;
                font-size: 16px;
                vertical-align: middle;
                position: relative;
                top: -1px;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
            .icon-gift:before {
                content: "\ebb3";
            }
            .icon-menu:before {
                content: "\ec67";
            }
            .icon-bookmark2:before {
                content: "\ecb4";
            }
            .icon-heart5:before {
                content: "\ece9";
            }
            .icon-share2:before {
                content: "\eec5";
            }
            .icon-share3:before {
                content: "\eedd";
            }
            .icon-comment:before {
                content: "\f02b";
            }
            /*! CSS Used from: https://peakd.com/assets/css/icons/peakfont/styles.css?v=xdj9x3eh */
            [class^="logo-"]:before,
            [class*=" logo-"]:before {
                font-family: "peakfont" !important;
                font-style: normal !important;
                font-weight: normal !important;
                font-variant: normal !important;
                text-transform: none !important;
                speak: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
            .logo-peakd:before {
                content: "\70";
            }
            .logo-hive:before {
                content: "\68";
            }
            /*! CSS Used from: https://peakd.com/assets/css/main.min.css */
            article {
                display: block;
            }
            a {
                background-color: transparent;
            }
            a:active,
            a:hover {
                outline: 0;
            }
            b,
            strong {
                font-weight: 700;
            }
            h1 {
                font-size: 2em;
                margin: 0.67em 0;
            }
            small {
                font-size: 80%;
            }
            img {
                border: 0;
            }
            hr {
                box-sizing: content-box;
                height: 0;
            }
            @media print {
                *,
                :after,
                :before {
                    background: 0 0 !important;
                    color: #000 !important;
                    box-shadow: none !important;
                    text-shadow: none !important;
                }
                a,
                a:visited {
                    text-decoration: underline;
                }
                a[href]:after {
                    content: " (" attr(href) ")";
                }
                img {
                    page-break-inside: avoid;
                }
                img {
                    max-width: 100% !important;
                }
                p {
                    orphans: 3;
                    widows: 3;
                }
                .label {
                    border: 1px solid #000;
                }
            }
            * {
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
            }
            :after,
            :before {
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
            }
            a {
                color: #1e88e5;
                text-decoration: none;
            }
            a:focus,
            a:hover {
                color: #166dba;
                text-decoration: none;
            }
            a:focus {
                outline: 5px auto -webkit-focus-ring-color;
                outline-offset: -2px;
            }
            img {
                vertical-align: middle;
            }
            hr {
                margin-top: 20px;
                margin-bottom: 20px;
                border: 0;
                border-top: 1px solid #ddd;
            }
            h1,
            h5,
            h6 {
                font-family: inherit;
                //font-weight: 400;
                line-height: 1.5384616;
                color: inherit;
            }
            h5 small,
            h6 small {
                //font-weight: 400;
                line-height: 1;
                color: #999;
            }
            h1 {
                margin-top: 20px;
                margin-bottom: 10px;
            }
            h5,
            h6 {
                margin-top: 10px;
                margin-bottom: 10px;
            }
            h5 small,
            h6 small {
                font-size: 75%;
            }
            h1 {
                font-size: 25px;
            }
            h5 {
                font-size: 17px;
            }
            h6 {
                font-size: 15px;
            }
            p {
                margin: 0 0 10px;
            }
            small {
                font-size: 92%;
            }
            .text-right {
                text-align: right;
            }
            .text-center {
                text-align: center;
            }
            .text-muted {
                color: #999;
            }
            ul {
                margin-top: 0;
                margin-bottom: 10px;
            }
            ul ul {
                margin-bottom: 0;
            }
            .list-inline {
                padding-left: 0;
                list-style: none;
                margin-left: -5px;
            }
            .list-inline > li {
                display: inline-block;
                padding-left: 5px;
                padding-right: 5px;
            }
            .caret {
                display: inline-block;
                width: 0;
                height: 0;
                margin-left: 2px;
                vertical-align: middle;
                border-top: 4px dashed;
                border-right: 4px solid transparent;
                border-left: 4px solid transparent;
            }
            .dropdown,
            .dropup {
                position: relative;
            }
            .dropdown-menu {
                position: absolute;
                top: 100%;
                left: 0;
                z-index: 1000;
                display: none;
                float: left;
                min-width: 160px;
                padding: 5px 0;
                margin: 2px 0 0;
                list-style: none;
                font-size: 13px;
                text-align: left;
                background-color: #fff;
                border: 1px solid transparent;
                border-radius: 3px;
                -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
                box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
                background-clip: padding-box;
            }
            .dropdown-menu .divider {
                height: 1px;
                margin: 9px 0;
                overflow: hidden;
                background-color: #e5e5e5;
            }
            .dropdown-menu > li > a {
                display: block;
                padding: 3px 20px;
                clear: both;
                //font-weight: 400;
                line-height: 1.5384616;
                color: #333;
                white-space: nowrap;
            }
            .dropdown-menu > li > a:focus,
            .dropdown-menu > li > a:hover {
                text-decoration: none;
                color: #333;
                background-color: #f5f5f5;
            }
            .dropdown-menu-right {
                left: auto;
                right: 0;
            }
            .dropup .dropdown-menu {
                top: auto;
                bottom: 100%;
                margin-bottom: 2px;
            }
            .btn-group {
                position: relative;
                display: inline-block;
                vertical-align: middle;
            }
            .label {
                display: inline;
                padding: 0.2em 0.6em 0.3em;
                font-size: 75%;
                font-weight: 700;
                line-height: 1;
                color: #fff;
                text-align: center;
                white-space: nowrap;
                vertical-align: baseline;
                border-radius: 0.25em;
            }
            a.label:focus,
            a.label:hover {
                color: #fff;
                text-decoration: none;
                cursor: pointer;
            }
            .label:empty {
                display: none;
            }
            .label-default {
                background-color: #999;
            }
            .label-default[href]:focus,
            .label-default[href]:hover {
                background-color: grey;
            }
            .label-primary {
                background-color: #2196f3;
            }
            .media {
                margin-top: 15px;
            }
            .media:first-child {
                margin-top: 0;
            }
            .media,
            .media-body {
                zoom: 1;
                overflow: hidden;
            }
            .media-body {
                width: 10000px;
            }
            .media-right {
                padding-left: 10px;
            }
            .media-left {
                padding-right: 10px;
            }
            .media-body,
            .media-left,
            .media-right {
                display: table-cell;
                vertical-align: top;
            }
            .media-middle {
                vertical-align: middle;
            }
            .panel {
                margin-bottom: 20px;
                background-color: #fff;
                border: 1px solid transparent;
                border-radius: 3px;
                -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
                box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
            }
            .panel-body {
                padding: 20px;
            }
            .panel-heading {
                padding: 15px 20px;
                border-bottom: 1px solid transparent;
                border-top-right-radius: 2px;
                border-top-left-radius: 2px;
            }
            .panel-title {
                margin-top: 0;
                margin-bottom: 0;
                font-size: 15px;
                color: inherit;
            }
            .panel-title > small {
                color: inherit;
            }
            .panel-footer {
                padding: 8px 20px;
                background-color: #fcfcfc;
                border-top: 1px solid #ddd;
                border-bottom-right-radius: 2px;
                border-bottom-left-radius: 2px;
            }
            .well {
                min-height: 20px;
                padding: 19px;
                margin-bottom: 20px;
                background-color: #fdfdfd;
                border: 1px solid #ddd;
                border-radius: 3px;
                -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
            }
            .panel-body:after,
            .panel-body:before {
                content: " ";
                display: table;
            }
            .panel-body:after {
                clear: both;
            }
            .pull-right {
                float: right !important;
            }
            .hidden {
                display: none !important;
            }
            .visible-lg-inline {
                display: none !important;
            }
            @media (min-width: 1200px) {
                .visible-lg-inline {
                    display: inline !important;
                }
            }
            a {
                cursor: pointer;
            }
            a:focus {
                outline: 0;
            }
            h1,
            h5,
            h6 {
                letter-spacing: -0.015em;
            }
            h5 small,
            h6 small {
                font-size: 13px;
            }
            a {
                outline: 0;
            }
            a.text-muted:hover {
                color: #333;
            }
            .position-left {
                margin-right: 8px;
            }
            .text-bold {
                font-weight: 700;
            }
            .text-semibold {
                font-weight: 500;
            }
            .text-size-large {
                font-size: 14px;
            }
            ul {
                padding-left: 25px;
            }
            .list-inline {
                margin-left: 0;
                font-size: 0;
            }
            .list-inline > li {
                padding-left: 0;
                padding-right: 20px;
                font-size: 13px;
            }
            .list-inline > li:last-child {
                padding-right: 0;
            }
            .list-inline-condensed > li {
                padding-right: 10px;
            }
            .list-inline-separate > li {
                padding-right: 20px;
                position: relative;
            }
            .list-inline-separate > li:before {
                content: "\2022";
                position: absolute;
                right: 8px;
                color: #ccc;
                top: 1px;
            }
            .list-inline-separate > li:last-child:before {
                content: none;
            }
            .caret {
                font-style: normal;
                //font-weight: 400;
                border: 0;
                margin: 0;
                width: auto;
                height: auto;
                text-align: center;
                margin-top: -1px;
            }
            .caret:after {
                content: "\e9c5";
                font-family: icomoon;
                display: block;
                font-size: 16px;
                width: 16px;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
            .dropdown-menu {
                min-width: 180px;
                padding: 8px 0;
                color: #333;
                -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
            }
            .dropdown-menu:not([class*="border-"]) {
                border-width: 0;
            }
            .dropdown-menu .divider {
                margin: 8px 0;
            }
            .dropdown-menu > li {
                position: relative;
                margin-bottom: 1px;
            }
            .dropdown-menu > li:last-child {
                margin-bottom: 0;
            }
            .dropdown-menu > li > a {
                padding: 8px 16px;
                outline: 0;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .dropdown-menu > li > a > i {
                margin-right: 16px;
                float: left;
                margin-top: 2px;
                top: 0;
            }
            .dropdown-menu > li > a > i.pull-right {
                margin-right: 0;
                margin-left: 16px;
            }
            .label {
                display: inline-block;
                font-weight: 500;
                padding: 2px 5px 1px 5px;
                line-height: 1.5384616;
                border: 1px solid transparent;
                text-transform: uppercase;
                font-size: 10px;
                letter-spacing: 0.1px;
                border-radius: 2px;
            }
            .label[href]:focus,
            .label[href]:hover {
                opacity: 0.85;
            }
            .label-default {
                border-color: #999;
            }
            .label-default[href]:focus,
            .label-default[href]:hover {
                background-color: #999;
            }
            .label-primary {
                border-color: #2196f3;
            }
            .label-striped {
                background-color: #f5f5f5;
                color: #333;
                border-left-width: 2px;
                padding: 6px 12px;
            }
            .label-striped.label-striped-right {
                border-left-width: 1px;
                border-right-width: 2px;
            }
            .label-striped {
                border-radius: 0;
            }
            .label-flat {
                background-color: transparent;
                border-width: 2px;
                border-radius: 0;
                padding: 1px 4px 0 4px;
            }
            .label-rounded {
                border-radius: 100px;
            }
            .label-rounded:not(.label-icon) {
                padding-left: 8px;
                padding-right: 8px;
            }
            .label-block {
                display: block;
            }
            .media {
                margin-top: 20px;
                position: relative;
            }
            .media,
            .media-body {
                overflow: visible;
            }
            .media-body,
            .media-left,
            .media-right {
                position: relative;
            }
            .media-right {
                padding-left: 20px;
            }
            .media-left {
                padding-right: 20px;
            }
            .panel {
                margin-bottom: 20px;
                border-width: 0;
                color: #333;
                -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
            }
            .panel-body {
                position: relative;
            }
            .panel-flat > .panel-heading + .panel-body {
                padding-top: 0;
            }
            .panel-heading {
                position: relative;
                padding-top: 20px;
                padding-bottom: 20px;
                border-top-right-radius: 3px;
                border-top-left-radius: 3px;
            }
            .panel-flat > .panel-heading {
                background-color: #fff;
            }
            .panel-flat > .panel-heading > .panel-title {
                margin-top: 2px;
                margin-bottom: 2px;
            }
            .panel-title {
                position: relative;
                font-size: 13px;
            }
            .panel-title > small:not(.display-block) {
                margin-left: 5px;
            }
            h5.panel-title {
                font-size: 17px;
            }
            .panel-footer {
                position: relative;
                padding-left: 0;
                padding-right: 0;
                border-bottom-right-radius: 3px;
                border-bottom-left-radius: 3px;
            }
            .panel-footer:after {
                content: "";
                display: table;
                clear: both;
            }
            .panel-footer-condensed {
                padding-top: 2px;
                padding-bottom: 2px;
            }
            .well {
                margin-bottom: 0;
                padding: 20px;
                -webkit-box-shadow: none;
                box-shadow: none;
            }
            .heading-elements {
                background-color: inherit;
                position: absolute;
                top: 50%;
                right: 20px;
                height: 38px;
                margin-top: -19px;
            }
            @media (max-width: 768px) {
                .heading-elements:not(.not-collapsible) {
                    position: static;
                    margin-top: 0;
                    height: auto;
                }
            }
            .panel-footer > .heading-elements {
                position: static;
                margin-top: 0;
                padding-right: 20px;
            }
            .panel-footer > .heading-elements:after {
                content: "";
                display: table;
                clear: both;
            }
            @media (max-width: 768px) {
                .panel-footer > .heading-elements:not(.not-collapsible) > .pull-right {
                    float: none !important;
                }
            }
            .heading-elements .list-inline {
                margin-bottom: 0;
            }
            .heading-elements:not(.visible-elements) .heading-text {
                float: left;
                margin-left: 20px;
            }
            .heading-elements:not(.visible-elements) .heading-text {
                display: inline-block;
            }
            .heading-elements:not(.visible-elements) .heading-text + .heading-text {
                margin-left: 20px;
            }
            .heading-elements:not(.visible-elements) .heading-text {
                margin-top: 9px;
            }
            @media (max-width: 768px) {
                .heading-elements:not(.not-collapsible) {
                    display: none;
                }
            }
            .valign-middle {
                vertical-align: middle;
            }
            .display-block {
                display: block;
            }
            .display-inline-block {
                display: inline-block;
            }
            .content-group {
                margin-bottom: 20px !important;
            }
            .no-margin {
                margin: 0 !important;
            }
            .no-margin-bottom {
                margin-bottom: 0 !important;
            }
            .mt-5 {
                margin-top: 5px !important;
            }
            .mt-10 {
                margin-top: 10px !important;
            }
            .mt-15 {
                margin-top: 15px !important;
            }
            .mb-5 {
                margin-bottom: 5px !important;
            }
            .mb-10 {
                margin-bottom: 10px !important;
            }
            .mb-15 {
                margin-bottom: 15px !important;
            }
            .ml-5 {
                margin-left: 5px !important;
            }
            .ml-15 {
                margin-left: 15px !important;
            }
            .mr-5 {
                margin-right: 5px !important;
            }
            .no-padding-right {
                padding-right: 0 !important;
            }
            .p-20 {
                padding: 20px !important;
            }
            .pb-10 {
                padding-bottom: 10px !important;
            }
            .pl-5 {
                padding-left: 5px !important;
            }
            .pl-20 {
                padding-left: 20px !important;
            }
            .pr-10 {
                padding-right: 10px !important;
            }
            .pr-20 {
                padding-right: 20px !important;
            }
            .border-teal {
                border-color: #009688;
            }
            .border-right-info-600 {
                border-right-color: #00acc1;
            }
            .border-right-slate-600 {
                border-right-color: #546e7a;
            }
            .text-pink,
            .text-pink:focus,
            .text-pink:hover {
                color: #e91e63 !important;
            }
            .text-teal,
            .text-teal:focus,
            .text-teal:hover {
                color: #009688 !important;
            }
            /*! CSS Used from: https://peakd.com/static/css/app.0cde01d33017ddd8f545ef4a596b3221.css */
            p {
                text-rendering: optimizeLegibility;
            }
            .flex-shrink-10000 {
                -ms-flex-negative: 10000;
                flex-shrink: 10000;
            }
            h1,
            h5,
            h6 {
                letter-spacing: normal;
            }
            .avatar {
                border-radius: 50%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: 50% 50%;
                border: 1px solid #e9e7e7;
            }
            .icon-large {
                font-size: 20px !important;
            }
            .stop-overflow {
                overflow-wrap: break-word;
                word-wrap: break-word;
                word-break: break-word;
            }
            .ellipsis-overflow {
                text-overflow: ellipsis;
                word-break: break-word;
                white-space: nowrap;
                overflow: hidden;
            }
            @media (max-width: 768px) {
                .panel-body,
                .panel-heading {
                    padding-left: 10px;
                    padding-right: 10px;
                }
                .heading-elements:not(.not-collapsible) {
                    display: block;
                }
                .panel-footer > .heading-elements:not(.not-collapsible) > .pull-right {
                    float: right !important;
                }
                .position-left {
                    margin-right: 1px;
                }
                .list-inline-separate > li {
                    padding-right: 16px;
                }
                .list-inline-separate > li:before {
                    right: 6px;
                }
            }
            .strikethrough {
                text-decoration: line-through;
            }
            .text-muted,
            h5 small,
            h6 small {
                color: #757575;
            }
            .reputation-label {
                background-color: hsla(0, 0%, 100%, 0.3);
                border-color: #c5c5c5;
                color: #757575;
                font-size: 12px;
                padding: 0 6px;
                margin-left: 1px;
                margin-top: 0 !important;
            }
            .dropdown-menu {
                padding: 5px 0;
            }
            .dropup .dropdown-menu {
                top: auto;
                bottom: 80%;
            }
            .dropdown-menu-xs > .dropdown-menu > li > a {
                padding-top: 6px;
                padding-bottom: 6px;
                font-size: 12px;
                line-height: 1.6666667;
            }
            @media (max-width: 768px) {
                .dropdown-menu-center > .dropdown-menu {
                    left: -90px;
                }
            }
            .label-read-time {
                padding: 5px 9px;
                margin-bottom: 4px;
            }
            .media-right.media-middle > .label-read-time:last-child {
                margin-bottom: 0;
            }
            .post-body {
                overflow-wrap: break-word;
                word-wrap: break-word;
                word-break: break-word;
            }
            .post-body img {
                max-height: 1000px;
                width: auto;
                height: auto;
                display: inline-block;
            }
            .post-body iframe,
            .post-body img {
                max-width: 100%;
                margin-top: 5px;
                margin-bottom: 5px;
            }
            .post-body p:not(:last-child) {
                margin-bottom: 1.5rem;
            }
            .post-body p:last-child {
                margin-bottom: 0.5rem;
            }
            .post-body hr {
                clear: both;
            }
            .media {
                width: 100%;
            }
            .media__video {
                position: relative;
                padding-bottom: 56.25%;
                height: 0;
                width: 100%;
                overflow: hidden;
                margin-top: 0;
            }
            .media__video iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
            iframe {
                border: 0;
            }
            .panel {
                -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.14);
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.14);
            }
            .panel-footer h5 {
                margin-top: 5px;
                margin-bottom: 5px;
            }
            .well.cross-post-well {
                padding: 10px;
                background-color: rgba(0, 0, 0, 0.02);
            }
            .action-icon[data-v-7b3d7b34] {
                font-size: 18px;
            }
            .action-icon-token[data-v-7b3d7b34] {
                font-size: 16px;
            }
            .list-inline-separate > li[data-v-7b3d7b34]:before {
                top: 8px;
            }
            .panel-footer-fixed[data-v-7b3d7b34] {
                position: -webkit-sticky;
                position: sticky;
                bottom: 0;
            }
            .panel-footer-fixed.panel-footer[data-v-7b3d7b34]:after {
                content: unset;
            }
            @media (max-width: 768px) {
                .ellipsis-overflow[data-v-2c695662] {
                    overflow: visible;
                }
            }
            .viewer[data-v-0e7766ec] img {
                cursor: pointer;
            }
            .post-body[data-v-0e7766ec] {
                font-size: 17px;
            }
            .post-body[data-v-0e7766ec] h5 {
                font-size: 20px;
                font-weight: 500;
            }
            .post-body[data-v-0e7766ec] h1 {
                font-size: 28px;
                font-weight: 500;
            }
            .post-body[data-v-0e7766ec] p > img:only-child {
                display: block;
                margin: 5px auto 2px;
            }
            .panel-full-post[data-v-2322c91e] .panel .panel-body,
            .panel-full-post[data-v-2322c91e] .panel .panel-heading {
                margin-left: 100px;
                margin-right: 100px;
            }
            .panel-full-post[data-v-2322c91e] .panel .panel-footer.panel-footer-post {
                padding-left: 120px !important;
                padding-right: 120px !important;
            }
            .panel-heading[data-v-184e9f84] {
                padding-bottom: 0;
                padding-top: 10px;
            }
            .media-post-author[data-v-184e9f84] {
                margin-top: 15px;
            }
            .list-inline.list-inline-condensed > li[data-v-184e9f84] {
                padding-right: 5px;
            }
            .cross-post-body[data-v-184e9f84] p:last-child {
                margin: 0;
            }
            #article-panel > .well.cross-post-well + .panel-footer.panel-footer-post[data-v-184e9f84] {
                border-top: none;
            }
            @media (max-width: 768px) {
                h1.post-title[data-v-184e9f84] {
                    font-size: 22px;
                }
                .media-post-author[data-v-184e9f84] {
                    margin-top: 5px;
                }
                .post-timestamp-label[data-v-184e9f84] {
                    display: block;
                }
            }
            hr[data-v-184e9f84] {
                clear: both;
            }
        }
    }
}
