.main-footer {
    box-sizing: border-box;
    //box-shadow: var(--softshadow-top);
    height: 62px;

    .optional-inner-max-width {
        padding: 10px 20px;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .main-footer-item a {
        display: flex;
        align-items: center;
        img {
            display: block;
            width: 30px;
        }
        p {
            padding-left: 5px;
            font-size: 13px;
            font-weight: bold;
            display: block;
        }
    }
}
