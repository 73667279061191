:root {
    --mainfont: "Quicksand", -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
        sans-serif;
    --softshadow-bottom: 0 4px 4px 0px rgb(245, 245, 245);
    --softshadow-top: 0 -4px 4px 0px rgb(245, 245, 245);
    --lightgrey: #dcdcdc;
    --lightestgrey: #f1f1f1;
    --darkgrey: #c8c8c8;
    --br-one: 4px;
    --maincolor: #e31337;
    --white: #ffffff;
    --black: #000000;

    //dbuzz styles
    --dbuzz-font: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    --dbuzz-bg-ns: #15202b;
    --dbuzz-hover-bg-ns: #192734;
    --dbuzz-text-ns: #ffffff;
    --dbuzz-border-ns: 1px solid rgb(56, 68, 77);

    --dbuzz-bg-g: #202225;
    --dbuzz-hover-bg-g: #2b2d31;
    --dbuzz-text-g: #ffffff;
    --dbuzz-border-g: 1px solid #2f3136;

    --dbuzz-bg: #ffffff;
    --dbuzz-hover-bg: #f5f8fa;
    --dbuzz-text: #000000;
    --dbuzz-border: 1px solid #e6ecf0;

    --dbuzz-red: #e53935; // #c73635;

    //ecency styles
    --ecency-font: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    --ecency-blue: #357ce6;
    --ecency-hover-blue: #1b68da;
    --ecency-br: 25px;
    --ecency-white-hover: #cfe1f9;
    --ecency-input-bg: #f5f5f5;
    --ecency-border: 1px solid #e7e7e7;
    --ecency-blue-border: 1px solid #357ce6;
    --ecency-placeholder-color: #c1c5c7;
}
