.user-page {
    min-height: 100vh;
    background-color: var(--lightestgrey);
}
.user-header,
.tabs-wrapper,
.user-send-berries {
    margin: 0 auto;
    max-width: 750px;
}

@media (min-width: 1250px) {
    .user-header,
    .tabs-wrapper,
    .user-send-berries {
        margin: unset;
        max-width: unset;
    }
    .user-page {
        display: grid;
        grid-template-areas:
            "header header"
            "tabs bmb";
        grid-template-columns: 750px 450px;
        grid-template-rows: auto 1fr;
        justify-content: center;
        column-gap: 15px;
        .user-header {
            grid-area: header;
        }
        .tabs-wrapper {
            grid-area: tabs;
            margin-top: 15px;
        }
        .user-send-berries {
            grid-area: bmb;
            position: sticky;
            z-index: 10;
            top: 0;
        }
    }
}
