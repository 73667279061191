.user-send-berries-wrapper {
    padding: 15px 0px;
    .user-send-berries {
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        padding: 20px;
        .send-berries-text {
            padding-bottom: 20px;
        }
        .berry-quantifier {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 20px;
            .berry-quantifier-image {
                width: 40px;
                object-fit: contain;
            }
            .number-radio-button {
                background-color: var(--lightgrey);
                color: var(--white);
                font-weight: bold;
                font-size: 18px;
                width: 40px;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 100px;
                cursor: pointer;
            }
            .number-radio-button.checked {
                background-color: var(--maincolor);
            }
            .berry-input {
                margin-left: 15px;
                width: 45px;
                height: 45px;
                border: 2px solid var(--lightgrey);
                text-align: center;
                border-radius: 4px;
            }
            input[type="number"]::-webkit-inner-spin-button {
                display: none;
            }
        }
        textarea {
            border: 2px solid var(--lightgrey);
            padding: 10px;
            border-radius: 4px;
            height: 80px;
            resize: none;
        }
        .support-button {
            margin-top: 20px;
        }
        .sign-method {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 20px;
            font-size: 12px;
            .sign-method-button {
                //padding: 5px 10px;
                padding: 0px 10px;
                cursor: pointer;
                /* color: var(--white);
                background-color: var(--lightgrey);
                border-top: 1px solid var(--black);
                border-bottom: 1px solid var(--black); */
                color: var(--darkgrey);
            }
            .sign-method-button.active {
                /* background-color: var(--maincolor);
                //color: var(--black); */
                color: var(--black);
            }
            .sign-method-button:first-of-type {
                /* border-left: 1px solid var(--black);
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px; */
            }
            .sign-method-button:last-of-type {
                /*  border-right: 1px solid var(--black);
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px; */
            }
        }
        .username-input {
            //text-align: center;
            border: 2px solid var(--lightgrey);
            border-radius: 4px;
            padding: 10px 15px;
            margin-top: 20px;
        }
        .error-input {
            box-shadow: 0 0 10px var(--maincolor);
            border: 2px solid var(--white);
        }
        .commission-message {
            text-align: center;
            margin-top: 20px;
            font-size: 12px;
        }
    }
}
