.home-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    .expand {
        flex-grow: 1;
    }
    .shrink {
        flex-grow: 0;
    }
}
