.generated-codes {
    flex-grow: 0;
    transition: flex-grow 0.25s linear;
    padding-bottom: 20px;
    .generated-codes-inner {
        max-width: 750px;
        padding: 20px;
        margin: 0 auto;
        display: grid;
        column-gap: 15px;
        row-gap: 15px;
        grid-template-columns: repeat(auto-fit, minmax(225px, 1fr));
        .one-generated-code {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-end;
            img {
                display: block;
                margin: 0 auto;
                object-fit: contain;
            }
            img.l {
                max-width: 100%;
                max-height: 100%;
            }
            img.m {
                max-width: 80%;
                max-height: 80%;
            }
            img.s {
                max-width: 60%;
                max-height: 60%;
            }
            .info-for-code {
                display: flex;
                font-size: 13px;
                align-items: center;
                p {
                    padding: 5px;
                }
                .img-size {
                    font-weight: bold;
                }
            }
            .to-copy {
                //margin-top: 10px;
                word-break: break-all;
                text-align: justify;
                font-size: 14px;
                padding: 10px;
                //background-color: rgb(255, 255, 223);
                background-color: rgb(255, 229, 209);
                border-radius: var(--br-one);
                cursor: pointer;
            }
        }
    }
}
