.user-page.dbuzz-g {
    font-family: var(--dbuzz-font);

    background-color: var(--dbuzz-bg-g);
    .user-send-berries-wrapper,
    .tabview-container {
        padding: 0;
    }
    .user-header,
    .user-home-post,
    .user-update-post,
    .user-send-berries,
    .user-tabs,
    .user-supporter {
        background-color: var(--dbuzz-bg-g) !important;
        border: var(--dbuzz-border-g) !important;
        color: var(--white) !important;
        margin-bottom: 0;
        margin-top: 0;
        a {
            color: var(--dbuzz-red) !important;
        }
        .user-about-text {
            a {
                color: var(--white) !important;
            }
        }
        input,
        textarea {
            color: var(--white) !important;
            border: var(--dbuzz-border-g);
            background-color: var(--dbuzz-hover-bg-g) !important;
        }
        .sign-method-button {
            color: var(--darkgrey) !important;
        }
        .sign-method-button.active {
            color: var(--white) !important;
        }
        .number-radio-button {
            background-color: var(--dbuzz-bg-g) !important;
            border: var(--dbuzz-border-g) !important;
        }
        .number-radio-button.checked {
            background-color: var(--dbuzz-red) !important;
        }
        .number-radio-button:not(.checked):hover {
            background-color: var(--dbuzz-hover-bg-g) !important;
        }

        .btn-one {
            background-color: var(--dbuzz-red) !important;
        }
        .supporter-memo {
            color: var(--black);
        }
        .berry-input {
            border: var(--dbuzz-border-g) !important;
        }
    }
    .user-tabs {
        p:hover {
            background-color: var(--dbuzz-hover-bg-g) !important;
        }
    }
    .user-update-post:hover {
        background-color: var(--dbuzz-hover-bg-g) !important;
    }
    @media (min-width: 1250px) {
        .user-send-berries-wrapper {
            margin-top: 15px !important;
        }
    }
}
