.error-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    h1 {
        font-size: 25px;
        padding-bottom: 50px;
    }
    img {
        width: 50%;
        max-width: 300px;
        padding-bottom: 50px;
    }
}
