.user-page.ecency {
    font-family: var(--ecency-font);
    background-color: var(--ecency-input-bg) !important;
    .user-header,
    .user-home-post,
    .user-update-post,
    .user-send-berries,
    .user-tabs,
    .user-supporter {
        .user-about-text {
            a {
                color: var(--black) !important;
            }
        }
        input,
        textarea {
            border: var(--ecency-border) !important;
            background-color: var(--ecency-input-bg) !important;
        }
        textarea,
        .username-input {
            border-radius: var(--ecency-br) !important;
        }
        textarea::placeholder,
        .username-input::placeholder {
            color: var(--ecency-placeholder-color);
        }
        .sign-method-button {
            color: var(--ecency-placeholder-color) !important;
        }
        .sign-method-button.active {
            color: var(--black) !important;
        }
        .number-radio-button {
            background-color: var(--white) !important;
            color: var(--ecency-blue) !important;
            border: var(--ecency-blue-border) !important;
            font-weight: 500 !important;
        }
        .number-radio-button.checked {
            background-color: var(--ecency-blue) !important;
            color: var(--white) !important;
        }
        .number-radio-button:not(.checked):hover {
            background-color: var(--ecency-blue) !important;
            color: var(--white) !important;
        }

        .btn-one {
            background-color: var(--ecency-blue) !important;
            border-radius: var(--ecency-br) !important;
        }
        .btn-one::first-letter {
            text-transform: capitalize;
        }
        .btn-one:hover {
            background-color: var(--ecency-hover-blue) !important;
        }
        .supporter-memo {
            color: var(--black);
        }
        .berry-input {
            border: var(--ecency-border) !important;
        }
    }
    .user-home-post,
    .user-update-post {
        a {
            color: var(--ecency-blue) !important;
        }
        a:hover {
            color: var(--ecency-blue) !important;
        }
    }
}
