.user-home-post {
    padding: 20px;
}

.user-home-post.loading,
.user-home-post.error,
.user-home-post.default {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
        text-align: center;
        margin: 0 !important;
    }
    a {
        font-weight: bold;
    }
}
