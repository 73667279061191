.user-supporter {
    background-color: var(--white);
    padding: 20px;
    margin-bottom: 15px;
    .supporter-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        .supporter-pic {
            display: block;
            width: 40px;
            height: 40px;
            object-fit: cover;
            border-radius: 100px;
        }
        .supporter-info-text {
            padding: 0px 10px;
            //font-size: 15px;
        }
    }
    .supporter-memo {
        //border: 2px solid var(--black);
        background-color: rgb(255, 229, 209);
        margin-top: 15px;
        /* padding: 15px; */
        border: 15px solid rgb(255, 229, 209);
        border-radius: 10px;
        //font-size: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        //line-height: 1.4;
    }
    .supporter-memo.expanded {
        display: block;
    }
}

.user-supporter.loading,
.user-supporter.error,
.user-supporter.default,
.user-supporter.nomore {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
        text-align: center;
        margin: 0 !important;
    }
    a {
        font-weight: bold;
    }
}
