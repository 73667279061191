@import "normalize.scss";
@import "variables.scss";
@import "fonts.scss";

body {
    font-family: var(--mainfont);
}

.btn-one {
    background-color: var(--maincolor);
    color: var(--white);
    padding: 15px;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    text-decoration: none;
}
.btn-one:hover {
    text-decoration: none;
}
.btn-one:focus,
.btn-one:visited,
.btn-one:active {
    border: none;
    outline: none;
    box-shadow: 0 0 0 2pt black;
}
.btn-one:disabled {
    background-color: var(--lightgrey);
}

.input-error input {
    box-shadow: 0 0 10px var(--maincolor);
    border: 2px solid var(--white);
}

[style*="--aspect-ratio"] > :first-child {
    width: 100%;
}
[style*="--aspect-ratio"] > img {
    height: auto;
}
@supports (--custom: property) {
    [style*="--aspect-ratio"] {
        position: relative;
    }
    [style*="--aspect-ratio"]::before {
        height: 0;
        content: "";
        display: block;
        padding-bottom: calc(100% / (var(--aspect-ratio)));
    }
    [style*="--aspect-ratio"] > :first-child {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
    }
}
.user-home-post {
    position: relative;
}

.videoWrapper {
    height: 0 !important;
    width: 100% !important;
    position: relative !important;
    padding-bottom: 56.25% !important; /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */
    :not(iframe) {
        display: none;
    }
    iframe {
        position: absolute !important;
        top: 0 !important;
        left: 0 !important;
        width: 100% !important;
        height: 100% !important;
    }
}
/* .videoWrapper iframe {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    //height: 100%;
} */
